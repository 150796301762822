a {
  text-decoration: none;
}

@keyframes levitate {
	0% {
    transform: translate(0px, 0px);
  }
	10% {
    transform: translate(0px, 2px);
  }
	20% {
    transform: translate(0px, 2px);
  }
	30% {
    transform: translate(0px, -3px);
  }
	40% {
    transform: translate(0px, 4px);
  }
	50% {
    transform: translate(0px, 5px);
  }
	60% {
    transform: translate(0px, -2px);
  }
	70% {
    transform: translate(0px, 3px);
  }
	80% {
    transform: translate(0px, 2px);
  }
	90% {
    transform: translate(0px, 1px);
  }
	100% {
    transform: translate(0px, 0px);
  }
}


.col {
  flex-direction: column;
}

@keyframes rotate {
	0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes scale {
	0% {
    transform: scale(1);
  }
	50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slide {
	0% {
    background-position: 0vh 0vh;
  }
	10% {
    background-position: 0vh 1vh;
  }
	20% {
    background-position: 0vh 2vh;
  }
	30% {
    background-position: 0vh 3vh;
  }
	40% {
    background-position: 0vh 4vh;
  }
	50% {
    background-position: 0vh 7vh;
  }
	60% {
    background-position: 0vh 5vh;
  }
	70% {
    background-position: 0vh 3vh;
  }
	80% {
    background-position: 0vh 2vh;
  }
	90% {
    background-position: 0vh 1vh;
  }
	100% {
    background-position: 0vh 0vh;
  }
}

.rotate {
  animation: rotate 10s ease infinite;
  transition: all 200ms cubic-bezier(.47,1.64,.41,.8);
  transform-origin: 0 0;
}

.magic-cursor {
  position: absolute;
  width: 30px;
  height: 30px;
  pointer-events: none;
  z-index:1000000;
  @media screen and (max-width: 640px) {
    visibility: hidden;
    display: none;
  }
}
.ball {
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    border: 2px solid #fff;
    border-radius: 1000px;
    pointer-events: none;
    opacity:1;
    .cursor-icon-container {
      align-self: center; 
      width: 15px;
      height: 15px;
      .cursor-icon {
        flex: 1;
        width: 15px;
        height: 15px;
      }
    }

}

.flex {
  display: flex;
  .item {
    flex: 1;
  }
}

.viewport {
  margin: 0 0 0 0;
  width: 100vw;
  overflow-x: hidden;
  position: fixed;
}

.fcont {
  height: 100%;
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.space-evenly {
  justify-content: space-evenly;
}

.fbody {
  display: flex;
}

.fleft {
  flex: 1;
  margin-right: 75px;
}

.fright {
  flex: 1;
  margin-left: 75px;
}

.p_main-page {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  .c_skip-icon {
    cursor: pointer;
    z-index: 2;
    position: fixed;
    right: 5vw;
    bottom: 6vh;
    transform: rotate(90deg);
    &:hover {
      animation: leaves 1s ease infinite;
    }
    &__tip {
      font-size: 14px;
      color: #000;
    }

    .icon {
      width: 30px;
    }
  }


  .advantages-divider {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
  }

  .full-page-controls {
    z-index: 9999;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    button {
      cursor: pointer;
      width: 66px;
      opacity: 0;
      height: 10vh;
      &:first-child {
        display: none;
      }
      &:last-child {
        display: none;
      }
    }
  }

  section {
    overflow: hidden;
  }
  section, 
  footer {
    height: 100vh;
    width: 100%;
    .container {
      max-width: 90%;
      margin: auto;
    }
  }

  &__hero {
    z-index: 1300;
    position: relative;
    box-shadow: 0 60px 50px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;

    .floating-background-white {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100vw;
      transform-origin: 0 0;
      background: rgb(28,28,28);
      background: linear-gradient(100deg, rgba(28,28,28,1) -150%, rgba(103,103,103,1) 3%);
    }

    .container {
      min-width: 95%;
    }

    .bt-container {
      transform: scale(4) translate(40%, -110%);
      opacity: 0;
      height: 85vh;
      width: 100%;
      position: absolute;
      width: 700px;
      top: 7vh;
      right: 4vw;
      z-index: 850;
      svg {
        height: 80vh;
      }
    }

    &__moto {
      position: relative;
      z-index: 950;
      margin-left: 5vw;
      font-family: Gilroy;
      font-size: 200px;
      font-weight: 900;
      line-height: 1;
      letter-spacing: 10px;
      text-align: left;
      color: #ffffff;

      .trails-main {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .trails-text {
        position: relative;
        width: 100%;
        max-height: 200px;
        @media screen and (max-height: 900px) {
          max-height: 150px;
        }
        @media screen and (max-height: 700px) {
          max-height: 120px;
        }
        opacity: 0;
      }

      &__container {
        min-height: 150px;
        &:nth-child(2) {
          margin-left: 50px;
        }
        &:nth-child(3) {
          margin-left: 115px;
        }

      }
      
      .divider {
        opacity: 0;
        margin-top: 27px;
        height: 1px;
        background: #707070;
        width: 95vw;
        margin-left: -10vw;
        filter: blur(10px);
      }

      .label-vertical {
        text-transform: uppercase;
        width: 330px;
        position: absolute;
        bottom: 155px;
        right: -140px;
        opacity: 0.5;
        font-family: Gilroy;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 0.35px;
        text-align: left;
        color: #626263;
        transform: rotate(90deg);
        filter: blur(10px);
        z-index: 950;
        opacity: 0;
        .dot {
          margin-right: 20px;
          width: 11px;
          height: 11px;
          opacity: 0.5;
          background-color: #626263;
          display: inline-block;
          border-radius: 50%;
        }
      }
    }

    &__start-now {
      display: flex;
      align-items: center;
      padding-top: 25px;
      position: relative;
      z-index: 950;
      .description-container {
        flex: 1;
        font-family: Gilroy;
        font-size: 24px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: right;
        color: #8bd5c7;
        opacity: 0;
      }
      .button-container {
        margin: 0 45px;
        .c_button {
          opacity: 0;
          transform: translate(-15px, -20px);
        }
      }
      .placeholder {
        flex: 1;
        .text {
          font-weight: 200;
          color: #7FFFD4;
          font-size: 20px;
          // padding-right: 50px;
          // margin-top: -150px;
        }
      }
    }
  }

  &__benefits {
    background-color: #393939;
    background: url('/benefits-background.png');
    background-position: center center;
    position: relative;
    z-index: 65;

    &__title {
      z-index: 100;
      opacity: 0;
      transform: translate(-20px, -30px);
      .c_title {
        white-space: nowrap;
        position: relative;
      }
    }

    .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__bt {
      position: absolute;
      display: block;
      z-index: 65;
      top: 0vh;
      left: 3%;
      height: 110vh;
      animation: levitate 10s ease infinite;
      opacity: 0;
      transform: translate(-20px, -90vh);
    }

    &__best-container {
      margin-top: 5vh;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-left: 75px;
      .best-item {
        flex: 1;
        height: 60px;
        font-family: Gilroy;
        font-size: 45px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.15;
        letter-spacing: 1.3px;
        text-align: left;
        color: #fafafa;
        margin-bottom: 25px;
        opacity: 0;
        transform: translate(20px, -30px);
        z-index: 299;
        &:nth-child(3) {
          margin-bottom: 50px;
        }
      }
      .button-link {
        z-index: 10;
        position: relative;
        transform: translate(20px, -30px);
        opacity: 0;
      }
      .three-facts {
        position: absolute;
        left: -321px;
        top: -50px;
        opacity: 0.05;
        font-family: Gilroy;
        font-size: 300px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.28;
        letter-spacing: 7.5px;
        text-align: right;
        color: #ffffff;
      }
    }
  }

  &__how-it-works {
    z-index: 2;
    position: relative;
    display: flex;
    background-image: linear-gradient(90deg, #fff 23%, rgb(104, 106, 114) 65%, #393A3E 93%);
    &__bt {
      position: absolute;
      display: block;
      z-index: 65;
      bottom: 90vh;
      left: 3%;
      height: 110vh;
      animation: levitate 10s ease infinite;
    }
    .wave {
      position: absolute;
      right: 0;
      left: 0%;
      bottom: 0;
      top: -20vh;
      background-image: url("/vertical-wave.svg");
      background-repeat: no-repeat;
      background-size: 70vh;
      z-index: 9999;
    }
    .wrapper {
      flex: 1;
      margin-top: -5vh;
      align-self: center;
    }
    &__title {
      margin-bottom: 45px;
      margin-top: 5vh;
      font-family: Gilroy;
      font-size: 100px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.28;
      letter-spacing: 2.5px;
      text-align: left;
      color: #fafafa;
      opacity: 0;
      transform: translate(20px, -30px);
    }

    .divider {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      opacity: 0;
      transform: translate(20px, -30px);
    }
    &__cell {
      position: relative;
      height: 35px;
      margin-bottom: 33px;
      &__lg {
        font-family: Gilroy;
        font-size: 35px;
        letter-spacing: 1.3px;
        line-height: 45px;
        color: rgb(255, 255, 255);
        transform: translateY(-50vh);
        opacity: 0;
        display: flex;
        text-align: right;
        justify-content: flex-end;
        align-items: center;
      }
      &__sm {
        display: flex;
        align-items: center;
        text-align: left;
        font-family: Gilroy;
        line-height: 50px;
        font-size: 14px;
        letter-spacing: 0.35px;
        color: rgba(255, 255, 255, 0.5);
        opacity: 0;
        transform: translate(20px, -30px);

        a {
          height: 45px;
        }
      }
      &__background {
        position: absolute;
        left: 0%;
        right: 0;
        bottom: -5px;
        top: -5px;
        // background-color: #393939;
      }
    }

    .item {
      &:first-child {
        margin-right: 75px;
      }

      &:last-child {
        margin-left: 75px;
        // transform: translateX(50vw);
      }
    }

  }
  @keyframes leaves {
      0% {
          transform: scale(1.0) rotate(90deg);
      }
      50% {
        transform: scale(1.5) rotate(80deg);
      }
      100% {
          transform: scale(1.0) rotate(90deg);
      }
  }
  &__advantages__main {
    background: rgba(255, 255, 255, 1);
    position: relative;
    overflow: hidden;
    user-select: none;
    display: flex;
    .container {
      min-width: 90%;
      flex: 1;
      align-self: center;
      transition: all .2s ease-in-out;
      &.visible {
        opacity: 1;
      }
      &.hidden {
        opacity: 0;
      }
    }

    &__title {
      margin-left: 75px;
      font-family: Gilroy;
      font-size: 100px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.28;
      letter-spacing: 2.5px;
      text-align: left;
      color: #3a3a3a;
      // max-width: 500px
      transform: translate(20px, -30px);
      opacity: 0;
    }

    &__stacked-item {
      margin-right: 75px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      &__title {
        font-family: Gilroy;
        font-size: 52px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.15;
        letter-spacing: 1.3px;
        text-align: right;
        color: #3a3a3a;
        margin-bottom: 2px;
        max-width: 500px;
        opacity: 0;
        transform: translate(20px, -30px);
      }
      &__description {
        opacity: 0;
        font-family: Gilroy;
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: 0.7px;
        text-align: right;
        color: #3a3a3a;
        max-width: 500px;
        transform: translate(20px, -30px);
      }

      &:first-child {
      }

      &:nth-child(2) {
        margin-top: 125px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      // justify-content: center;
      position: relative;
    }
  }

  &__why-investors-choose {
    z-index: 2;
    height: 80vh;
    padding-top: 5vh;
    background-color: #393939;
    position: relative;
    display: flex;
    margin-top: -5px;
    .container {
      overflow: hidden;
      min-width: 90%;
      position: relative;
      flex: 1;
      align-self: center;
      .title-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .left-column {
        margin-right: 75px;
      }
      .right-column {
        margin-left: 75px;
        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }
    }
    &__title {
      text-shadow: -10px -2px 0px rgb(189, 45, 160);
      font-family: Gilroy;
      font-size: 100px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 80px;
      letter-spacing: 2.5px;
      text-align: right;
      color: #ffffff;
      opacity: 0;
    }
    &__bt-container {
      position: relative;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &__title {
        border-left: 10px solid #fff;
        position: absolute;
        background-color: #393939;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
    
    &__tile {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      opacity: 0;
      transform: translateY(-30px) translateX(20px);
      height: 160px;
      max-width: 150px;
      &__icon {
        width: 59px;
        height: 59px;
        margin: auto;
        display: flex;
      }
      &__description {
        max-width: 230px;
        // margin: 25px auto 0;
        font-family: Gilroy;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  &__one-main-account {
    margin-top: 5px;
    // background: -webkit-gradient(linear, left top, right bottom, color-stop(101%,#ff4500), color-stop(7%,#7fffd4));
    background-color: #ffffff;
    position: relative;
    display: flex;
    .client-icon {
      max-height: 500px;
      filter: blur(100px);
      transform: translate(25vw, 0px) scale(1.3);
    }

    .container {
      flex: 1;
      align-self: center;
      position: relative;
      .item {
        &:first-child {
          display: flex;
          align-items: center;
        }
        &:last-child {
          max-height: 440px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
        }
      }
    }

    &__bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    
    .bt {
      animation: levitate 5s ease infinite;
      position: absolute;
      right: 5vw;
      top: -5vh;
      height: 115vh;
      opacity: .3;
    }

    &__title {
      font-family: Gilroy;
      font-size: 100px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.28;
      letter-spacing: 2.5px;
      text-align: left;
      color: #393A3E;
      opacity: 0;
      transform: translate(20px, -30px);
      
    }

    &__description {
      font-family: Gilroy;
      font-size: 28px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: 0.7px;
      text-align: left;
      color: #393A3E;
      opacity: 0;
      transform: translate(20px, -30px);
    }

    .c_button {
      opacity: 0;
      transform: translate(20px, -30px);
    }
  }

  &__about-billtrade {
    z-index: 500;
    position: relative;
    overflow: hidden;
    display: flex;

    &__sponsors {
      margin-top: 44px;
      display: flex;
      width: 100%;
      justify-content: space-around;
      opacity: 0;
      transform: translateX(-30vw);
      img {
        // flex: 1;
        width: 100px;
        height: auto;
      }
    }

    .container {
      position: relative;
      flex: 1;
      align-self: center;
    }
    .left-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }


    &__title {
      margin-right: 75px;
      font-family: Gilroy;
      font-size: 100px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.28;
      letter-spacing: 2.5px;
      text-align: right;
      color: #fafafa;
      opacity: 0;
      transform: translate(-20px, -30px);
    }

    &__description {
      transform: scale(.3);
      opacity: 0;
      max-height: 100px;
      margin-right: 75px;
      font-family: Gilroy;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: 0.6px;
      text-align: right;
      color: #fafafa;
      opacity: 0;
      transform: translate(-20px, -30px);
    }

    &__numbers {
      .c_billtrade-counter {
        height: 622px;
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        .item {
          position: relative;

          .number-vector {
            transition: all 200ms cubic-bezier(.47,1.64,.41,.8);
            transform: scale(0) rotate(-7deg) translateX(-50px) translateY(-50px);
            &.active {
              transform: scale(1) rotate(0deg) translateX(0px) translateY(0px);
            }
          }
          .percent {
            transform: scale(0) rotate(-7deg) translateX(-50px) translateY(-50px);
          }

          &:nth-child(4) {
            max-width: 623px;
            .item {
              &:nth-child(1) {
                width: 80px;
                margin-right: -20px;
              }
              &:nth-child(2) {
                margin-left: -35px;
                width: 60px;
              }
              &:nth-child(3) {
                margin-left: -55px;
                width: 100px;
              }
              &:nth-child(4) {
                margin-left: -35px;
              }
            }
          }
          margin-bottom: 5px;
          .divider {
            position: absolute;
            border-bottom: 1px solid #626263;
            color: #9B9B9D;
            font-size: 17px;
            line-height: 25px;
            text-align: right;
            left: 52px;
            transition: all 300ms cubic-bezier(.47,1.64,.41,.8);
            transform: translate(-10px, -10px);
            opacity: 0;
          }
          &:nth-child(1) {
            max-width: 232px;
            .divider {
              width: 252px;
              bottom: 20px;
            }
          }
          &:nth-child(2) {
            max-width: 352px;
            .divider {
              width: 332px;
              bottom: 16px;
            }
          }
          &:nth-child(3) {
            max-width: 243px;
            .divider {
              width: 443px;
              bottom: 23px;
            }
          }
          &:nth-child(4) {
            max-width: 603px;
            .divider {
              width: 553px;
              bottom: 25px;
            }
            .numbers-container {
              max-width: 425px;
              display: flex;
              .item {
                flex: 1;
                &:nth-child(1) {
                  width: 80px;
                  margin-right: -20px;
                }
                &:nth-child(2) {
                  margin-left: -35px;
                  width: 60px;
                }
                &:nth-child(3) {
                  margin-left: -55px;
                  width: 100px;
                }
                &:nth-child(4) {
                  margin-top: -2px;
                  margin-left: -35px;
                }
              }
            }
          }
        }
      }
    }
  }

  &__top-members {
    display: flex;
    .container {
      min-width: 90%;
    }
    &__title {
      .text {
        color: #7FFFD4;
        text-align: right;
        font-weight: 900;
        font-size: 75px;
        line-height: 75px;
        letter-spacing: 1.88px;
        color: #7FFFD4;
        font-family: Gilroy;
        max-width: 400px;
      }
      .placeholder {

      }
    }

    &__body {
      .subtitle {
        white-space: nowrap;
        max-width: 400px;
        position: relative;
        .text {
          right: 0;
          top: 270px;
          position: absolute;
          text-align: left;
          font-family: Gilroy;
          font-size: 28px;
          line-height: 30px;
          letter-spacing: 0.7px;
          color: #5C9D89;
          text-transform: uppercase;
          transform-origin: 100% 0;
          transform: rotate(90deg);
        }
      }
      .member-placeholder {
        height: 170px;
      }
      .carousel-container {
        padding-left: 40px;
        flex: 1;
        height: 340px;
        margin: 0 auto;
        overflow: hidden;
        touch-action: pan-x;
      }
      
      .carousel-track {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      
      .carousel-card {
        flex: 0 0 170px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        color: white;
      }
      
      .carousel-card-inner {
        flex-direction: column;
        width: 290px;
        height: 290px;
        border-radius: 5px;
      }
      
      .carousel-title {
        margin-top: 20px;
        font-size: 1.5em;
      }
      
      .carousel-text {
        padding: 1em;
        font-size: 14px;
        white-space: pre-wrap;
        text-align: left;
        font-family: sans-serif;
      }
    }

    &.red {
      .text {
        color: #FF4500;
      }
    }
    &.green {
      .text {
        color: #7FFFD4;
      }
    }
  }

  &__affiliate-program {
    display: flex;
    background-color: #fff;
    position: relative;
    .wrapper {
      flex-direction: column;
      flex: 1;
      align-self: center;
    }
    .divider {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      opacity: 0;
      transform: translate(20px, -30px);
    }
    .container {
      min-width: 80%;
    }
    .c_button {
      opacity: 0;
      margin-left: auto;
    }
    .item {
      position: relative;
      &:first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
    &__title {
      margin-left: 150px;
      // margin-top: 121px;
      font-family: Gilroy;
      font-size: 100px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.28;
      letter-spacing: 2.5px;
      text-align: left;
      color: #3a3a3a;
      opacity: 0;
      transform: translate(20px, -30px);
    }

    .stats {
      margin-left: 35px;
      display: block;
      height: 45vh;
    }

    &__description-item {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      height: 71px;
      opacity: 0;
      margin-right: 75px;
      font-family: Gilroy;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: 0.7px;
      text-align: right;
      color: #3a3a3a;
      max-width: 308px;
    }
  }

  &__media-about-us {
    display: flex;
    flex-direction: column;
    .container {
      min-width: 90%;
      margin: 0 auto!important;
    }
    &__title {
      margin-bottom: 20vh;
      margin-top: 10vh;
      font-family: Gilroy;
      font-size: 200px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.75;
      letter-spacing: 5px;
      text-align: left;
      color: #ffffff;
    }

    &__slider-container {
      .image {
        text-align: right;
        max-width: 35vw;
      }
      .text {
        margin-left: 80px;
        max-width: 525px;
        font-family: Gilroy;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: 0.45px;
        text-align: left;
        color: #ffffff;
      }
      .paginator {
        user-select: none;
        cursor: pointer;
        margin: 23px 300px;
        max-width: 50px;
        opacity: 0.5;
        font-family: Gilroy;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: 0.45px;
        text-align: left;
        color: #ffffff;
      }
    }
  }

  &__billtrade-benefits {
    background-color: #fff;
    z-index: 800;
    overflow: hidden;
    position: relative;
    display: flex;
    .container {
      z-index: 10;
    }

    &__divider {
      position: absolute;
      left: -75px;
      top: 0;
      opacity: 0;
      transform: translate(20px, -30px);
    }

    .placeholder {
      position: absolute;
      top: 0;
      right: 50%;
      left: 0;
      bottom: 0;
      z-index: 9997;
    }
    .container {
      flex: 1;
      align-self: center;
      min-width: 90%!important;
    }

    &__list-item {
      cursor: pointer;
      user-select: none;
      font-family: Gilroy;
      font-size: 22px;
      line-height: 1.18;
      letter-spacing: 0.7px;
      text-align: right;
      margin-bottom: 28px;
      opacity: 0;
      transform: translate(-20px, -30px);
      color: #9C9C9C;
      &.active {
        color: #3a3a3a;
        font-weight: bold;
      }
    }

    .item {
      position: relative;
      height: 500px;
      display: flex;
      flex-direction: column;
      &:first-child {
        margin-right: 75px;
        justify-content: center;
      }
      &:last-child {
        margin-left: 75px;
        justify-content: space-evenly;
      }
    }

    &__title {
      font-family: Gilroy;
      font-size: 75px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.28;
      letter-spacing: 2.5px;
      color: #3a3a3a;
      opacity: 0;
      transform: translate(220px, -30px);
    }

    &__description {
      width: 430px;
      font-family: Gilroy;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: 0.7px;
      text-align: left;
      color: #3a3a3a;
      opacity: 0;
      transform: translate(220px, -30px);
      height: 120px;
    }
    .c_button {
      z-index: 9999;
      position: relative;
      opacity: 0;
      transform: translate(20px, -30px);
    }
  }

  &__footer {
    padding-top: 100px;
    box-sizing: border-box;
    position: relative;
    background-color: #393939;
    justify-content: space-between;
    flex-direction: column;

    .placeholder {
      flex: 1;
      max-height: 5vh;
    }
    .container {
      min-width: 90%;
      margin: 0 auto!important;
    }

    .item {
      position: relative;
    }

    &__bg {
      position: absolute;
      top: 0;
      left: 20px;
      height: 100vh;
    }

    &__title {
      position: relative;
      // margin-top: 150px;
      margin-left: 75px;
      font-family: Gilroy;
      font-size: 90px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.28;
      letter-spacing: 2.5px;
      text-align: left;
      color: #ffffff;
    }

    &__description {
      position: relative;
      margin-left: 75px;
      margin-top: 30px;
      width: 534px;
      height: 145px;
      font-family: Gilroy;
      font-size: 28px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: 0.7px;
      text-align: left;
      color: #ffffff;
    }

    &__socials {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: auto;
      width: 800px;
      .icon {
        height: 50px;
      }
    }

    &__links {
      align-items: center;
      .link {
        font-family: Gilroy;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: 0.7px;
        text-align: right;
        color: #ffffff;
        margin-top: 30px;
      }
    }

    form {
      position: relative;
      z-index: 20;
      max-width: 320px;
      margin-left: auto;
      margin-right: 75px;
      input {
        // margin-top: 190px;
        box-sizing: border-box;
        padding-left: 20px;
        width: 317px;
        height: 49px;
        border: solid 1px #252525;
        background-color: #4d4d4d;
        margin-bottom: 20px;
      }
      textarea {
        resize: none;
        box-sizing: border-box;
        padding-left: 20px;
        padding-top: 20px;
        width: 317px;
        height: 179px;
        border: solid 1px #252525;
        background-color: #4d4d4d;
        margin-bottom: 50px;
      }
    }
  }

  &__footer_2 {
    display: flex;
    flex-direction: column;
    height: 100vh;

    &__menu-container {
      width: 80%;
      margin: auto;
      display: flex;
      border-top: 1px solid #707070;
      padding-top: 20px;
      .logo {
        margin-right: 100px;
      }

      .items {
        margin-right: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        a {
          font-size: 18px;
          font-family: Gilroy;
          font-weight: bold;
          color: #959698;
        }
      }
    }
    .detection {
      transition: all .2s ease-in-out;
      font-size: 22px;
      font-family: Gilroy;
      color: #959698;
      width: 80%;
      margin: auto;
      line-height: 1.18;
      letter-spacing: 0.7px;
    }
    &__line {
      position: relative;
      background-color: #3c4044;
      align-items: center;
      display: flex;
      height: 40px;
      .text {
        font-family: Gilroy;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: 0.7px;
        text-align: left;
        color: #828282;
        padding-left: 130px;
      }
    }
  }
}