@media screen and (max-width: 1919px) and (min-width: 1600px) {
  .p_main-page {
    &__hero {
      &__moto {
        margin-top: 0;
        font-size: 150px;
      }
    }

    &__benefits {
      &__title {
        font-size: 80px;
      }

      .container {
        padding-top: 0;
      }

      &__best-container {
        .best-item {
          font-size: 35px;
        }
        margin-top: 0;
      }

      .c_button {
        height: 66px;
        &__text {
          font-size: 25px;
        }
      }

      &__bt {
        height: 110vh;
      }
    }

    &__how-it-works {
      &__title {
        font-size: 80px;
        margin-top: 10vh;
        margin-bottom: 40px;
      }

      &__cell {
        height: 40px;
        margin-bottom: 35px;
        &__lg {
          font-size: 35px;
        }
        &__sm {
          font-size: 14px;
        }
      }

      .c_vertical-divider {
        top: 5vh;
      }
    }

    &__advantages__main {
      &__title {
        font-size: 80px;
      }

      &__stacked-item {
        &__title {
          font-size: 45px;
        }
        &__description {
          font-size: 22px;
        }
        &:first-child {
          margin-top: 5vh;
        }
      }
    }

    &__why-investors-choose {
      &__title {
        font-size: 80px;
      }

      &__bt-container {
        height: 80px;
      }
      &__tile {
        &__description {
          font-size: 12px;
        }
      }
      .container {
        .right-column {
          // margin-top: 30px;
        }
      }
    }

    &__one-main-account {
      &__title {
        font-size: 80px;
      }
      &__description {
        font-size: 22px;
      }
      .item {
        &:first-child {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    &__about-billtrade {
      &__title {
        font-size: 80px;
      }
      .container {
        margin: 0 auto!important;
      }
    }

    &__top-members {
      overflow: hidden;
      .container {
        min-width: 90%;
      }
      &__title {
        font-size: 70px;
        margin-top: 5vh;
      }
      &__subtitle {
        font-size: 20px;
        top: 180px;
      }

      .members {
        max-height: 80vh;
        margin: 3vh auto 10vh auto;
      }
    }

    &__affiliate-program {
      &__title {
        font-size: 80px;
      }
      .c_vertical-divider {
        top: 7vh;
      }
      &__description-item {
        font-size: 20px;
      }
      img {
        height: 50vh;
      }
    }

    &__media-about-us {
      &__title {
        font-size: 100px;
        text-align: right;
      }
      &__description {
        font-size: 18px;
      }
      &__slider-container {
        .item {
          &:first-child {
            max-width: calc(50vw - 75px);
          }
        }
      }
    }
    
    &__billtrade-benefits {

      &__title {
        font-size: 60px;
      }

      &__description {
        font-size: 25px;
        // margin-top: 30vh;
      }

      &__list-item {
        font-size: 25px;
        &:first-child {
          // margin-top: 30vh;
        }
      }
    }

    &__footer {
      padding-top: 50px;
      &__title {
        font-size: 80px;
      }
      &__description {
        font-size: 24px;
      }
      form {
        textarea {
          margin-bottom: 30px;
        }
      }

      &__socials {
        // margin: 10vhvh auto 10vh auto;
        .icon {
          height: 30px;
        }
      }

      &__bg {
        height: 70vh;
      }
    }
  }
}