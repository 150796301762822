@media screen and (max-width: 1599px) and (min-width: 1280px) {
  .p_main-page {
    &__hero {
      &__moto {
        margin-top: 0;
        font-size: 150px;
      }
      .bt-container {
        svg {
          // height: 60vh;
        }
      }
      &__start-now { 
        .placeholder {
          .text {
            font-size: 20px;
          }
        }
      }
    }


    &__benefits {
      &__title {
        font-size: 70px;
      }
      .best-item {
        font-size: 30px;
      }
    }

    &__how-it-works {
      &__title {
        font-size: 70px;
        margin-top: 5vh;
        margin-bottom: 40px;
      }
      &__cell {
        &__lg {

        }
        &__sm {
          font-size: 14px;
        }
      }
    }

    &__advantages__main {
      &__title {
        font-size: 70px;
        // margin-top: 10vh;
      }

      &__stacked-item {
        &__title {
          font-size: 40px;
        }
        &__description {
          font-size: 20px;
        }

        &:first-child {
          margin-top: 5vh;
        }
      }
    }

    &__why-investors-choose {
      &__title {
        font-size: 70px;
      }
      .container {
        .item {
          &:first-child {
            align-self: center;
          }
        }
        .right-column {
          // margin-left: 75px;
        }
      }
    }
    &__one-main-account {
      .container {
        .item {
          align-self: center;
        }
      }
      &__title {
        font-size: 70px;
      }
      &__description {
        margin: 47px 0 30px;
        font-size: 22px;
      }
    }

    &__about-billtrade {
      &__title {
        font-size: 70px;
      }
    }

    &__affiliate-program {
      &__title {
        font-size: 70px;
      }
      img {
        height: 50vh;
      }
    }

    &__media-about-us {
      &__title {
        font-size: 100px;
        text-align: right;
      }
      &__slider-container {
        .item {
          &:first-child {
            max-width: calc(50vw - 75px);
          }
        }
      }
    }
    
    &__billtrade-benefits {

    }

    &__footer {
      // padding-top: 30px;
      &__title {
        font-size: 70px;
      }
      &__description {
        font-size: 22px;
      }

      &__socials {
        // margin: 10vh auto 10vh;
        .icon {
          height: 30px;
        }
      }

      .line {
        bottom: 5vh;
      }

      &__links {
        .link {
          font-size: 16px;
        }
      }
    }
  }
}