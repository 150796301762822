@media screen and (max-width: 900px) and (orientation : landscape) {
  .p_main-page {
    .container {
      margin: 0 auto;
    }

    &__hero {
      .container {
        margin: 0 auto;
      }
      &__moto {
        margin-left: 15px;
        font-size: 55px;
        .trails-text {
          height: 100px;
        }
        &__container {
        }
      }

      &__background {
        width: 379px;
        height: 557px;
      }

      &__start-now {
        flex-direction: column;
        .description-container {
          height: auto;
          margin-bottom: 10vh;
        }
        .button-container {
          max-width: unset;
          padding-right: 0px;
          margin: auto;
        }
      }
    }

    &__benefits {
      background-image: url('/benefits-background.png');
      background-repeat: no-repeat;
      background-size: cover;
      .container {
        // margin: 50px!important;
        // max-width: calc(100vw - 120px)!important;
      }
      &__title {
        font-size: 35px;
        max-width: 90%;
        text-align: left;
        &:after {
          opacity: 0.5;
          top: 40vh;
          left: 0;
          right: 0;
          margin: auto;
          width: 99px;
          height: 267px;
        }
      }
      .empty {
        flex: none;
        display: none;
      }
      &__best-container {
        margin-left: 0;
        margin-top: 10vh;
        .best-item {
          text-align: left;
          font-size: 20px;
          margin-right: 5vw;
          &:nth-child(3) {

          }
        }
      }

      .c_button {
        // height: 50px!important;
        // width: 100% !important;
        // &__text {
        //   text-align: center!important;
        //   font-size: 22px!important;
        //   margin: auto;
        // }
      }

      .three-facts {
        font-size: 100px;
        right: -15vw;
        visibility: hidden;
      }

      // &__bg {
      //   visibility: hidden;
      //   display: none;
      // }
      &__bt {
        top: 100vh;
        height: 100vh;
      }
    }

    &__how-it-works {
      padding-left: 10vw;
      &:before {
        content: '';
        width: 3px;
        height: 70vh;
        position: absolute;
        top: 35vw;
        left: 5vh;
        background: #FF4500;
      }
      .divider {
        visibility: hidden;
      }
      &__title {
        font-size: 35px;
        margin-top: 15vh;
        max-width: 80%;
      }
      .container {
        flex-direction: column;
        .item {
          &:first-child {
            margin-right: 0;
          }
          &:last-child {
            margin-left: 0;
          }
        }
      }

      &__title-placeholder {
        visibility: hidden;
        display: none;
      }

      .cels {
        margin-bottom: 50px;
        .item {
          &:last-child {
            margin-left: 15px;
            margin-top: -260px;
          }
        }
      }

      &__cell {
        height: 30px;
        margin-bottom: 29px;
        &__lg {
          font-size: 20px;
          text-align: left;
        }
        &__sm {
          font-size: 12px;
          text-align: left;
          line-height: 14px;
          letter-spacing: 0.35px;
          max-width: 270px;
        }
      }


      .c_vertical-divider {
        visibility: hidden;
      }
    }

    &__advantages__main {
      .flex {
        flex-direction: column-reverse;
      }
      &__title {
        white-space: normal;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        font-size: 50px;
        // margin-top: 5vh;
      }

      &__stacked-item {
        margin-right: 0;
        &__title {
          font-size: 25px;
          white-space: normal;
          text-align: left;
        }
        &__description {
          font-size: 16px;
          white-space: normal;
          text-align: left;
        }

        &:first-child {
          margin-top: 5vh;
        }
      }
    }

    &__why-investors-choose {
      height: auto!important;
      .flex {
        flex-direction: column;
      }
      &__title {
        font-size: 50px;
        margin-right: 0;
        text-align: center;
        margin-top: 5vh;
      }
      &__tile {
        &__description {
          font-size: 12px;
        }
      }
    }

    &__one-main-account {
      height: auto!important;
      .container {
        flex-direction: column-reverse;
      }
      &__title {
        font-size: 40px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 5vw;
      }
      &__description {
        margin-left: 5vw;
        margin-top: 20px;
        font-size: 18px;
      }
      &__button {
        width: 90vw;
      }
      .client-icon {
        height: auto;
        width: 90vw;
        margin-right: auto;
        margin-left: auto;
        margin-top: 5vh;
      }
    }

    &__about-billtrade {
      height: auto!important;
      .container {
        margin: 0 auto!important;
        flex-direction: column;
      }
      &__title {
        width: auto;
        font-size: 70px;
        text-align: center;
        margin-left: 5vw;
        margin-right: 5vw;
        margin-top: 0;
      }
      &__description {
        text-align: center;
        font-size: 22px;
        margin-left: 5vw;
        margin-right: 5vw;
      }
      &__numbers {
        margin-left: 5vw;
        margin-top: 10vh;
        img {
          height: auto;
          width: 80vw;
        }
      }
    }

    &__zen {
      &__title {
        font-size: 50px;
      }
      &__subtitle {
        font-size: 30px;
        &:first-child {
          margin-top: 10vh;
        }
      }
    }

    &__top-members {
      overflow: hidden;
      height: auto!important;
      .container {
        min-width: 90%;
        flex-direction: column;
      }
      &__title {
        font-size: 70px;
        margin-top: 25vh;
        text-align: center;
      }
      &__subtitle {
        font-size: 20px;
        top: 180px;
      }

      .members {
        max-height: 80vh;
        margin: 3vh auto 10vh auto;
        margin-left: auto!important;
      }
    }

    &__affiliate-program {
      height: auto!important;
      .container {
        flex-direction: column-reverse;
      }
      &__title {
        font-size: 60px;
        text-align: center;
        margin-top: 25vh;
        margin-bottom: 10vh;
      }
      &__description-item {
        font-size: 20px;
        text-align: left;
        margin: 10vh auto;
        font-weight: bold;
        &:first-child {
          margin-top: 50px;
        }
        &:last-child {
          margin-bottom: 50px;
        }
      }
      img {
        height: 50vh;
        margin: auto;
        margin-bottom: 10vh;
      }
      .item {
        &:last-child {
          margin-left: 0;
        }
      }
      .c_button {
        margin: 5vw auto;
        width: 100%;
        &__text {
          width: 100%;
          text-align: center;
        }
      }
    }

    &__media-about-us {
      height: auto!important;
      &__title {
        font-size: 90px;
      }
      &__slider-container {
        flex-direction: column;
        .image {
          max-width: initial;
          text-align: left;
          margin-left: 3vw;
        }
        .text {
          margin-left: 5vw;
        }
      }
    }
    
    &__billtrade-benefits {
      height: auto!important;
      .container {
        flex-direction: column;
      }
      &__title {
        font-size: 50px;
        left: 15vw;
        top: 0;
      }

      &__description {
        width: auto;
        font-size: 20px;
        margin-top: 25vh;
        margin-left: 20vw;
        text-align: right;
        margin-right: 5vw;
        white-space: normal;
      }

      &__list-item {
        font-size: 20px;
        margin-right: 5vw;
        &:first-child {
          margin-top: 10vh;
        }
      }
    }

    &__footer {
      height: auto!important;
      .container {
        flex-direction: column;
      }
      &__title {
        font-size: 50px;
        margin-top: 10vh;
        margin-right: 5vw;
      }
      &__description {
        font-size: 22px;
        width: auto;
        margin-right: 5vw;
      }
      form {
        input {
          margin-top: 10vh;
        }
      }

      &__socials {
        max-width: 100%;
        margin: 5vh auto 5vh auto;
        .icon {
          height: 30px;
        }
      }

      &__links {
        .item {
          &:first-child {
            margin-bottom: 20px;
          }
        }
        .link {
          font-size: 16px;
          text-align: center;

        }
      }
      .text {
        text-align: center;
        padding-left: 0;
      }

      &__bg {
        height: auto;
        width: 80vw;
        left: 20px;
      }
    }
  }
}

// @media screen and (max-width: 900px) and (orientation : landscape) {
//   .p_main-page {
//     &__hero {
//       &__moto {
//         font-size: 50px;
//       }
//     }
//   }
// }