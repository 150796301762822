@media screen and (max-width: 1280px) and (min-width: 641px) {
  .p_main-page {
    overflow-x: hidden;
    &__hero {
      &__moto {
        margin-top: 0;
        font-size: 120px;
        &__container {
          min-height: 120px;
        }
      }

      .description-container {
        font-size: 18px;
      }
      .bt-container {
        width: 620px;
        right: 2vw;
      }
      &__background {
        height: 60vh;
        top: 2vh;
        right: -10vw;
          &.b {
            height: 45vh;
          }
          &.t {
            height: 45vh;
          }
      }

      .divider {
          width: 90%;
          left: 0;
          right: 0;
          margin: auto;
      }
    }

    &__benefits {
      // background-image: url('/benefits-background.png');
      // background-repeat: no-repeat;
      // background-size: cover;
      &__title {
        font-size: 55px;
        &:after {
          opacity: 0.5;
        }
      }

      .container {
        .flex {
          &:first-child {
            .empty {
              margin-left: 0;
              @media (orientation: portrait) {
                display: none;
              }
            }
          }
          &:last-child {
            .empty {
              margin-left: 0;
              @media (orientation: portrait) {
                display: none;
              }
            }
          }
        }
      }

      &__best-container {
        .best-item {
          font-size: 22px;
          &:nth-child(3) {
            margin-bottom: 5vh;
          }
        }

        .three-facts {
          font-size: 200px;
          right: 0;
        }

        
      }
    }

    &__how-it-works {
      // .flex {
      //   flex-direction: column;
      // }
      // .c_vertical-divider {
      //   visibility: hidden;
      // }
      &__title {
        // margin-left: 5vh!important;
        // width: 60%;
        // margin-top: 5vh;
        // margin-bottom: 10vh;
        font-size: 55px;
      }

      // .cels {
      //   .item {
      //     &:first-child {
      //       margin-left: 5vh;
      //       margin-right: 0;
      //     }
      //     &:last-child {
      //       margin-left: 7vh;
      //       margin-top: -345px;
      //     }
      //   }
      // }
      &__cell {
        &__lg {
          font-size: 35px;
          line-height: 35px;
          // text-align: left;
        }
        &__sm {
          font-size: 12px;
          line-height: 35px;
          // text-align: left;
        }
      }
    }

    &__advantages__main {
      &__title {
        font-size: 55px;
      }

      &__stacked-item {
        &__title {
          font-size: 40px;
        }
        &__description {
          font-size: 18px;
        }
      }
    }

    &__why-investors-choose {
      &__title {
        font-size: 55px;
      }
      &__tile {
        height: 170px;
        max-width: 140px;
        &__description {
          letter-spacing: normal;
          transform: scale(0.9);
          font-size: 12px;
        }
      }
    }

    &__one-main-account {
      .client-icon {
        height: 400px;
        width: 400px;
      }
      .container {
        .item {
          &:last-child {
            margin-left: 0;
          }
        }
      }
      &__title {
        font-size: 45px;
      }
      &__description {
        font-size: 18px;
      }
    }

    &__about-billtrade {
      &__title {
        margin-right: 0;
        font-size: 55px;
      }
      &__description {
        margin-right: 0;
        font-size: 22px;
      }
      &__numbers {
        min-width: 60vw;
        .c_billtrade-counter {
          transform: scale(0.8);
        }
      }
    }

    &__top-members {
      &__title {
        font-size: 55px;
        margin-bottom: 5vh;
        .text {
          text-align: left;
        }
      }
      &__body {
        .subtitle {
          white-space: nowrap;
          font-size: 20px;
          max-width: 20px;
        }
      }
    }

    &__affiliate-program {
      .flex {
        flex-direction: row;
      }
      .c_vertical-divider {
        visibility: hidden;
      }
      &__title {
        max-width: unset;
        font-size: 60px;
      }
      img {
        height: 40vh;
      }
    }

    &__media-about-us {
      &__title {
        font-size: 100px;
        text-align: right;
      }
      &__slider-container {
        .text {
          font-size: 16px;
          margin-left: 125px;
          max-width: 415px;
        }
        .item {
          &:first-child {
            max-width: calc(50vw);
          }
        }
      }
    }
    
    &__billtrade-benefits {
      &__title {
        font-size: 50px;
        top: -30vh;
      }

      &__description {
        font-size: 20px;
      }

      &__list-item {
        font-size: 20px;
      }
    }

    &__footer {
      padding-top: 30px;
      &__title {
        font-size: 55px;
        margin-right: 5vw;
      }
      &__description {
        width: auto;
        font-size: 22px;
        margin-right: 5vw;
      }
      form {
        textarea {
          margin-bottom: 20px;
        }
      }

      &__socials {
        .icon {
          height: 30px;
        }
      }

      &__links {
        .item {
          &:last-child {
            margin-right: 50px;
          }
        }
        .link {
          font-size: 16px;
        }
      }

      &__bg {
        height: 70vh;
      }
    }
  }
}
