.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-item {
  display: block!important;
  margin-bottom: 20px;
  padding-bottom: 5px;
  .link {
    &:focus {
      outline: unset;
    }
  }
  .link {
    text-decoration: none;
    font-family: Gilroy;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: 0.7px;
    text-align: left;
    color: #fff;
  }
}

.logo {
  &:focus {
    outline: unset;
  }
}

.flex {
  flex: 1;
  position: relative;
}

.df {
  display: flex;
}

.frow {
  display: flex;
  flex-direction: row;
}

.fcol {
  display: flex;
  flex-direction: column;
}

.fcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.fevenly {
  justify-content: space-evenly;
}

.fpad {
  padding: 0 30px;
  box-sizing: border-box;
}

.c_app {

  .one {
    box-shadow: 0 60px 50px 0 rgba(0, 0, 0, 0.5);
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    height: 100vh;
    background: rgb(28,28,28);
    background: linear-gradient(150deg, rgba(28,28,28,1) 25%, rgba(103,103,103,1) 125%);

    .background {
      position: absolute;
      top: 65px;
      left: 7px;
      right: -2px;
      margin: auto;
      opacity: 0.8;

      @media screen and (orientation : landscape) {
        transform: scale(0.6);
        top: -55px;
        right: 0;
        left: unset;
      }
    }

    .title {
      flex: 1;
      font-size: 78px;
      font-weight: bold;
      letter-spacing: 3.9px;
      color: #FFFFFF;
      opacity: 1;
      display: flex;
      justify-content: center;
      min-height: 215px;
      opacity: 0;
      transform: translate(-10px, -50px);
      @media screen and (orientation : landscape) {
        min-height: 120px;
        svg {
          // margin-top: -50px;
          // margin-right: 50px;
          height: 120px;
        }
      }
    }

    .subtitle {
      margin-top: 10px;
      margin-bottom: 20px;
      flex: 1;
      letter-spacing: 0.35px;
      color: #FFFFFF;
      font-size: 14px;
      text-align: center;
      max-height: 30px;
      opacity: 0;
    }

    .text {
      color: #7FFFD4;
      font-size: 16px;
      text-align: center;
      font-family: Gilroy;
      font-weight: 300;
    }

    .button {
      opacity: 0;
      transform: translate(0%, 50%);
    }
  }

  .two {
    background-image: url(/benefits-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    height: 100vh;

    .title {
      flex: 1;
      font-weight: bold;
      font-size: 35px;
      letter-spacing: 0.88px;
      color: #FAFAFA;
      // opacity: 0;
      // transform: translate(-10px, -15px);
    }

    .body {
      .item {
        height: 20px;
        margin-bottom: 16px;
        text-align: left;
        flex: 1;
        font-size: 20px;
        letter-spacing: 0.5px;
        color: #FAFAFA;
        // opacity: 0;
        // transform: translate(-10px, -15px);
      }
    }
  }

  .three {
    padding-left: 40px;
    padding-right: 30px;
    background: #393A3E 0% 0% no-repeat padding-box;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media screen and (orientation : landscape) {
      height: auto;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .title {
      position: relative;
      flex: 1;
      max-height: 140px;
      @media screen and (orientation : landscape) {
        margin: 20px;
      }
      .lg {
        text-align: left;
        font-weight: bold;
        font-size: 35px;
        letter-spacing: 0.88px;
        color: #FAFAFA;
      }
      .sm {
        letter-spacing: 0;
        color: #9B9B9D;
        text-transform: lowercase;
        font-size: 12px;
      }

      &:before {
        content: '';
        position: absolute;
        height: 82vh;
        width: 3px;
        top: 2vh;
        left: -20px;
        background: #FF4500;
        border-radius: 6px;
        @media screen and (orientation : landscape) {
          height: 350px;
        }
      }
    }

    .body {
      max-height: 400px;
      display: flex;
      flex-direction: column;
      flex: 1;
      @media screen and (orientation : landscape) {
        margin: 20px;
      }
      .item {
        flex: 1;
        .lg {
          margin-bottom: 8px;
          text-align: left;
          font-size: 20px;
          letter-spacing: 0.5px;
          color: #FAFAFA;
        }
        .sm {
          padding-left: 8px;
          text-align: left;
          font-size: 14px;
          letter-spacing: 0.35px;
          color: #9B9B9D;
        }
      }
    }
  }

  .four {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    padding-right: 30px;
    background: #ffffff;
    .slider {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 10px;
      @media screen and (orientation : landscape) {
        transform: scale(0.6);
      }
    }
    .title {
      font-size: 35px;
      font-weight: bold;
      letter-spacing: 0.88px;
      color: #393A3E;
      align-self: center;
      .desc {
        font-size: 14px;
        letter-spacing: 0;
        color: #9B9B9D;
        font-weight: 300;
        text-transform: lowercase;
      }
    }

    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      // &:first-child {
      //   justify-content: center;
      // }
      .top, .bot {
        flex: 1;
        .item-title {
          font-size: 20px;
          letter-spacing: 0.5px;
          color: #393A3E;
        }
        .item-subtitle {
          font-size: 14px;
          letter-spacing: 0.35px;
          color: #393A3E;
        }
      }
    }

    .with-title {
      flex-direction: row;
    }

  }

  .five {
    // height: 100vh;
    display: flex;
    flex-direction: column;
    background: #393A3E 0% 0% no-repeat padding-box;
    .title {
      display: flex;
      padding-top: 30px;
      padding-bottom: 30px;
      display: flex;
      justify-content: center;
      text-align: center;
      flex: 1;
      font-size: 35px;
      font-weight: bold;
      letter-spacing: 0.88px;
      color: #FAFAFA;
      @media screen and (orientation : landscape) {
        max-width: unset;
      }
    }
    .body {
      flex: 1;
      display: flex;
      flex-direction: column;
      .item {
        justify-content: space-evenly;
        display: flex;
        flex-direction: row;
        &__tile {
          height: 150px;
          width: 150px;
          display: flex;
          flex-direction: column;
          &__icon {
            display: flex;
            justify-content: center;
            flex: 1;
            justify-content: center;
          }
          &__description {
            flex: 1;
            text-align: center;
            font-size: 14px;
            letter-spacing: 0.35px;
            color: #FFFFFF;
            max-width: 40vw;
          }
        }
      }
    }
  }

  .six {
    height: 100vh;
    background-color: #fff;
    @media screen and (orientation : landscape) {
      height: 100%;
      padding: 40px 0;
    }
    .title {
      width: 80%;
      align-self: center;
      justify-self: flex-start;
      flex: 1;
      font-size: 35px;
      font-weight: bold;
      letter-spacing: 0.88px;
      color: #393A3E;
    }
    .description {
      width: 80%;
      align-self: center;
      flex: 1;
      font-size: 14px;
      letter-spacing: 0.35px;
      color: #393A3E;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      .client-icon {
        height: 300px;
        @media screen and (orientation : landscape) {
          height: 100vh;
          margin: 20px 0 ;
        }
      }
    }
    .button {
      flex: 1;
      .c_button {
        margin: auto;
      }
    }
  }

  .seven {
    padding-left: 40px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    @media screen and (orientation : landscape) {
      height: auto;
      padding-bottom: 5vh;
      flex-direction: row;
    }
    .title {
      padding-top: 50px;
      max-height: 200px;
      height: 100px;
      max-width: 180px;
      flex: 1;
      .lg {
        color: #fff;
        font-size: 35px;
        font-weight: bold;
      }
      .sm {
        text-align: left;
        font-size: 12px;
        letter-spacing: 0;
        color: #9B9B9D;
        text-transform: lowercase;
      }
    }
    .icon {
      display: flex;
      justify-content: center;
      flex: 1;
      .numbers-icon {
        width: 95vw;
      }
    }
  }

  .eight {
    // height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: rgba(194,255,235,1);
    background-color: -moz-linear-gradient(top, rgba(194,255,235,1) 0%, rgba(255,102,46,1) 100%);
    background-color: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(194,255,235,1)), color-stop(100%, rgba(255,102,46,1)));
    background-color: -webkit-linear-gradient(top, rgba(194,255,235,1) 0%, rgba(255,102,46,1) 100%);
    background-color: -o-linear-gradient(top, rgba(194,255,235,1) 0%, rgba(255,102,46,1) 100%);
    background-color: -ms-linear-gradient(top, rgba(194,255,235,1) 0%, rgba(255,102,46,1) 100%);
    background-color: linear-gradient(to bottom, rgba(194,255,235,1) 0%, rgba(255,102,46,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c2ffeb', endColorstr='#ff662e', GradientType=0 );
    background-color: -webkit-gradient(linear, left top, right bottom, color-stop(101%,#ff4500), color-stop(7%,#7fffd4));
    .title {
      padding: 60px 60px 0 60px;
      flex: 1;
      .lg {
        letter-spacing: 0.88px;
        color: #393A3E;
        font-size: 35px;
        font-weight: bold;
      }
      .sm {
        font-size: 12px;
        letter-spacing: 0;
        color: #393A3E;
        text-transform: lowercase;
      }
    }
    .body {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 50px 40px 0 30px;
      .item {
        display: flex;
        .level {
          max-width: 80px;
          flex: 1;
          .label {
            font-size: 14px;
            letter-spacing: 0.35px;
            color: #3A3A3A;
            padding-right: 5px;
          }
          .number {
            font-size: 68px;
            font-weight: bold;
            letter-spacing: 1.7px;
            color: #3A3A3A;
          }
        }
        .description {
          align-self: center;
          flex: 1;
          font-size: 14px;
          letter-spacing: 0.35px;
          color: #3A3A3A;
        }
      }
    }

    .text {
      padding: 60px 60px 0 60px;
      flex: 1;
      font-size: 14px;
      letter-spacing: 0.35px;
      color: #3A3A3A;
    }

    .button {
      padding: 15px 0 60px;
      justify-content: center;
      flex: 1;
      .c_button {
        margin: 0 auto!important;
      }
    }

  }

  .nine {
    position: relative;
    height: 100vh;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    .title {
      position: absolute;
      bottom: 0;
      top: -250px;
      margin: auto;
      left: 50px;
      // width: 44px;
      height: 40px;
      transform: matrix(0, 1, -1, 0, 0, 0);
      transform-origin: 0 0;
      text-align: left;
      font-weight: bold;
      font-size: 35px;
      letter-spacing: 0.88px;
      color: #3A3A3A; 
    }
    .subtitle {
      max-height: 15vh;
      padding: 0 60px 10px 60px;
      flex: 1;
      letter-spacing: 0.4px;
      color: #3A3A3A;
      font-weight: bold;
      font-size: 16px;
      align-items: flex-end;
      display: flex;
    }
    .description {
      max-height: 150px;
      padding: 0 60px 0 60px;
      flex: 1;
      font-size: 14px;
      letter-spacing: 0.35px;
      color: #3A3A3A;
    }
    .body {
      padding: 0 60px 30px 60px;
      flex: 1;
      display: flex;
      flex-direction: column;
      .item {
        flex: 1;
        font-size: 14px;
        letter-spacing: 0.35px;
        color: #3A3A3A;
        opacity: 0.5;
      }
    }
    .button {
      flex: 1;
      display: flex;
      .c_button {
        margin: auto;
      }
    }
  }

  footer {
    padding-top: 5vh;
    display: flex;
    flex-direction: column;

    .title {
      padding-left: 50px;
      padding-right: 30px;
      padding-bottom: 10px;
      flex: 1;
      font-size: 35px;
      font-weight: bold;
      letter-spacing: 0.88px;
      color: #FAFAFA;
    }
    .description {
      font-weight: 300;
      padding-left: 50px;
      padding-right: 30px;
      flex: 1;
      font-size: 20px;
      letter-spacing: 0.5px;
      color: #FAFAFA;
    }

    form {
      padding: 30px;
      max-width: 320px;
      input {
        box-sizing: border-box;
        padding-left: 20px;
        width: 317px;
        height: 49px;
        border: solid 1px #252525;
        background-color: #4d4d4d;
        margin-bottom: 20px;
      }
      textarea {
        resize: none;
        box-sizing: border-box;
        padding-left: 20px;
        padding-top: 20px;
        width: 317px;
        height: 179px;
        border: solid 1px #252525;
        background-color: #4d4d4d;
        margin-bottom: 50px;
      }
      .c_button {
        width: 100%;
        margin: auto;
        &__text {
          text-align: center;
          margin: auto;
        }
      }
    }

    .socials {
      display: flex;
      padding-bottom: 30px;
      padding-right: 10px;
      padding-left: 10px;
      .item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {          
          height: 30px;
          width: 30px;
        }
      }
    }

    .menu {
      padding-left: 30px;
      .item {
        padding-bottom: 15px;
        &:first-child {
          padding-bottom: 50px;
        }
        .link {
          font-family: Gilroy;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.18;
          letter-spacing: 0.7px;
          text-align: left;
          color: #fff;
        }
      }
    }

    .detection {
      font-size: 18px;
      font-family: Gilroy;
      color: #959698;
      padding: 30px;
      border-bottom: 1px solid #707070;
      border-top: 1px solid #707070;
    }
    .rights {
      background-color: #3c4044;
      padding: 6px;
      align-items: center;
      display: flex;
      max-height: 40px;
      .text {
        font-family: Gilroy;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: 0.7px;
        text-align: left;
        color: #828282;
        padding-left: 20px;
      }
    }
  }
}